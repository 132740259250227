function Infrastructure() {
    return (
      <>
{/* Header Start */}
<div className="container-fluid bg-breadcrumb">
<div className="bg-breadcrumb-single" />
<div className="container text-center py-5" style={{ maxWidth: 900 }}>
  <h4
    className="text-white display-4 mb-4 wow fadeInDown"
    data-wow-delay="0.1s"
  >
    Infrastructure Solutions
  </h4>
 
</div>
</div>
<div className="container">
<section className="portfolio section-padding" id="portfolio">
  <div className="row">
    <div className="section-title  wow zoomIn">
      <div className="indented">
        <section>
          <h2>With Celersoft infrastructure solutions, organizations can:</h2>
          <ul>
            <li>Enjoy tailored solutions for complex environments</li>
            <li>Predict spending more easily and consistently</li>
            <li>
              Secure guaranteed service levels for infrastructure performance
            </li>
            <li>
              Gain greater business agility with the ability to access
              infrastructure on demand
            </li>
            <li>
              Improve operational efficiency by offloading routine maintenance
              and monitoring to CelerSoft experts
            </li>
            <li>
              Increase cost efficiency by paying only for the infrastructure
              needed at any given time
            </li>
          </ul>
          <h3>Infrastructure Solutions for Modern IT Environments</h3>
          <p>
            Empower your business with robust, scalable, and secure
            infrastructure solutions designed to support today’s digital
            demands. Our Infrastructure Solutions team specializes in building
            and optimizing IT infrastructure that aligns with your business
            goals, ensuring high performance, reliability, and seamless
            scalability. From cloud integration to on-premises data centers, our
            solutions cater to businesses across industries, equipping them with
            the necessary tools and support for a resilient IT environment.
          </p>
          <h3>Our Infrastructure Solution Offerings</h3>
          <ol type={1}>
            <li>
              IT Infrastructure Assessment and Strategy
              <ul>
                <li>
                  Comprehensive assessment of your existing infrastructure to
                  identify gaps and opportunities for optimization.
                </li>
                <li>
                  Strategic planning to align IT infrastructure with current and
                  future business objectives.
                </li>
              </ul>
            </li>
            <li>
              {" "}
              Cloud Infrastructure Services
              <ul>
                <li>
                  Design, deployment, and management of cloud infrastructure
                  (public, private, or hybrid) tailored to your business needs.
                </li>
                <li>
                  Secure and scalable cloud solutions, with ongoing support for
                  smooth migration and minimal downtime.
                </li>
              </ul>
            </li>
            <li>
              Data Center Solutions
              <ul>
                <li>
                  Design and deployment of on-premises and hybrid data centers
                  with a focus on security, energy efficiency, and operational
                  resilience.
                </li>
                <li>
                  Support for hardware management, virtualization, and
                  automation to ensure uninterrupted performance.
                </li>
              </ul>
            </li>
            <li>
              Network Infrastructure Solutions
              <ul>
                <li>
                  Comprehensive network design, setup, and optimization for
                  faster, more reliable connectivity.
                </li>
                <li>
                  Solutions for WAN, LAN, and SD-WAN to support multi-location
                  and remote access demands.
                </li>
              </ul>
            </li>
            <li>
              Storage and Backup Solutions
              <ul>
                <li>
                  Scalable storage solutions to manage data growth securely and
                  cost-effectively.
                </li>
                <li>
                  Backup and disaster recovery services to ensure data integrity
                  and continuity in the event of disruptions.
                </li>
              </ul>
            </li>
            <li>
              Virtualization and Hyper-Converged Infrastructure
              <ul>
                <li>
                  Enable greater resource efficiency with virtualization and
                  hyper-converged systems, maximizing IT assets while minimizing
                  physical footprint.
                </li>
                <li>
                  Solutions for virtual machines, storage, and networking to
                  simplify infrastructure management.
                </li>
              </ul>
            </li>
            <li>
              Infrastructure Monitoring and Management{" "}
              <ul>
                <li>
                  24/7 monitoring and support for proactive issue detection and
                  quick response.
                </li>
                <li>
                  Automated alerts, reporting, and regular maintenance to
                  optimize infrastructure performance and longevity.
                </li>
              </ul>
            </li>
          </ol>
          <h3>Why Choose Our Infrastructure Solutions?</h3>
          <ul>
            <li>Over 30+ successful engagements in last 2 years</li>
            <li>
              More than 90% of engagements involve development &amp;
              implementation
            </li>
            <li>Over 95% of work done offshore</li>
            <li>90% of all projects are completed on time </li>
            <li>Strong Alliances with Oracle, SAP, and Salesforce.com</li>
          </ul>
          <ul>
            <li>
              <strong>Industry Expertise</strong>: Decades of experience
              designing and managing complex IT environments across various
              sectors.
            </li>
            <li>
              <strong>Scalable and Secure Solutions</strong>: Infrastructure
              tailored to grow with your business needs, keeping security and
              compliance as a top priority.
            </li>
            <li>
              <strong>End-to-End Support</strong>: From planning to
              implementation and ongoing management, we provide support at every
              stage of the infrastructure lifecycle.
            </li>
          </ul>
          <p>
            Our Infrastructure Solutions are designed to help your organization
            stay agile, secure, and efficient in today’s fast-paced digital
            landscape. <a href="index.html#contact">Contact Us</a> to learn how
            we can help build a reliable IT foundation that powers your business
            growth.
          </p>
        </section>
      </div>
    </div>
  </div>
</section>
</div>
      </>
    );
};

export default Infrastructure