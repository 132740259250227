import { BrowserRouter as Router, Routes, Route  } from "react-router-dom";
import Home from './pages/Home';
import About from './pages/About';
import Management from './pages/Management';
import ManagedServices from './pages/ManagedServices';
import Services from './pages/Services';
import Infrastructure from './pages/Infrastructure';
import Business from './pages/Business';
import Erp from './pages/Erp.jsx';
import Cyber from './pages/Cyber';
import Micro from './pages/Micro';
import It from './pages/It';
import Solutions from './pages/Solutions';
import CaseStudies from './pages/CaseStudies';
import ERPCaseStudies from './pages/ERPCaseStudies';
import Careers from './pages/Careers';
import Contact from './pages/Contact';
import Header from './components/Header';
import Footer from './components/Footer';

function App() {
    return (
      <div className="App">
        <Router>
          <Header/>
         <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/management" element={<Management />} />
            <Route path="/managed-services" element={<ManagedServices />} />
            <Route path="/services" element={<Services />} />
            <Route path="/infrastructure" element={<Infrastructure />} />
            <Route path="/Business" element={<Business />} />
            <Route path="/Erp" element={<Erp />} />
            <Route path="/Cyber" element={<Cyber />} />
            <Route path="/Micro" element={<Micro />} />
            <Route path="/It" element={<It />} />
            <Route path="/Solutions" element={<Solutions />} />
            <Route path="/case-studies" element={<CaseStudies />} />
            <Route path="/erp-case-studies" element={<ERPCaseStudies />} />
            <Route path="/Careers" element={<Careers />} />
            <Route path="/Contact" element={<Contact />} />
         </Routes>
         <Footer/>
        </Router>
      </div>
    );
}

export default App;

