import React, { useState } from 'react';
function Contact() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [response, setResponse] = useState('');
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      const formData = new FormData();
      formData.append('name', name);
      formData.append('email', email);
      formData.append('subject', subject);
      formData.append('message', message);
      
      //const res = await fetch('http://localhost/celerphp/contact2.php', {
      const res = await fetch('https://react.acetechnow.com/contact2.php', {
        method: 'POST',
        body: formData
      });
  
      const result = await res.json();
      setResponse(result.message);
    };
    return (
        <>
  {/* Header Start */}
  <div className="container-fluid bg-breadcrumb contactbanner">
    <div className="bg-breadcrumb-single" />
    <div className="container text-center py-5" style={{ maxWidth: 900 }}>
      <h4
        className="text-white display-4 mb-4 wow fadeInDown"
        data-wow-delay="0.1s"
      >
        Contact
      </h4>
     
    </div>
  </div>
  {/* Header End */}
 
  <section className="contact_area" id="contact">
  <div className="container-fuild">
    <div className="row">
      <div className="section-title text-center wow zoomIn">
        <h2>Looking for help with your IT Project? .</h2>
        <p>Send us a quick note and a Sr Salesperson will get back to you real soon</p>
      </div>
      <div className="col-md-4 col-sm-4 col-xs-12"></div>
      <div className="col-md-4 col-sm-4 col-xs-12">
        <div className="contact">
        {/* <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST"> */}
        <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="form-group col-md-12">
                <input
                  className="form-control"
                  id="first-name" 
                  value={name} 
                  onChange={(e) => setName(e.target.value)}
                  name="name"
                  placeholder="Name"
                  required="required"
                  type="text"
                />
              </div>
              <div className="form-group col-md-12">
                <input
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="Email"
                  required="required"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group col-md-12">
                <input
                  className="form-control"
                  id="subject"
                  name="subject"
                  placeholder="Subject"
                  required="required"
                  type="text"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </div>
              <div className="form-group col-md-12">
                <textarea
                  className="form-control"
                  id="description"
                  name="message"
                  placeholder="Your Message"
                  required="required"
                  rows={6}
                  defaultValue={""}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
              <div className="col-md-12">
                <div className="actions">
                  <input
                    className="btn btn-lg btn-contact-bg"
                    id="submitButton"
                    name="submit"
                    title="Submit Your Message!"
                    type="submit"
                    defaultValue="Send message"
                  />
                </div>
              </div>
            </div>
          </form>
          {response && <p>{response}</p>}
        </div>
      </div>
      <div className="col-md-4 col-sm-4 col-xs-12"></div>
      {/* END COL */}
    </div>
    {/*- END ROW */}
  </div>
  {/*- END CONTAINER */}
</section>

</>      
    );
};

export default Contact