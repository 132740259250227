function Cyber() {
    return (
      <>

{/* Header Start */}
<div className="container-fluid bg-breadcrumb">
<div className="bg-breadcrumb-single" />
<div className="container text-center py-5" style={{ maxWidth: 900 }}>
  <h4
    className="text-white display-4 mb-4 wow fadeInDown"
    data-wow-delay="0.1s"
  >
    Cyber Security
  </h4>
 
</div>
</div>
  {/* START PORTFOLIO */}
  <div className="container">
  <section className="portfolio section-padding" id="portfolio">
    <div className="row">
      <div className="section-title  wow zoomIn">
        <div className="indented">
          <h2>Cybersecurity Solutions to Protect Your Digital Assets</h2>
          <p>
            In today’s digital landscape, robust cybersecurity is essential for
            safeguarding sensitive data and ensuring business continuity. Our{" "}
            <strong>Cybersecurity Services</strong> offer end-to-end protection
            for your IT infrastructure, designed to prevent, detect, and respond
            to evolving cyber threats. With our expert guidance and advanced
            security solutions, organizations can minimize risk, achieve
            compliance, and maintain customer trust.
          </p>
          <h3>Our Cybersecurity Service Offerings</h3>
          <ol>
            <li>
              Security Assessment and Risk Management
              <ul>
                <li>
                  Comprehensive security assessments to identify vulnerabilities
                  and potential security gaps.
                </li>
                <li>
                  Risk management solutions to prioritize threats and implement
                  strategic mitigation plans.
                </li>
              </ul>
            </li>
            <li>
              Threat Detection and Incident Response{" "}
              <ul>
                <li>
                  Real-time monitoring and threat detection for early
                  identification of suspicious activities.
                </li>
                <li>
                  Rapid incident response services to contain and neutralize
                  threats before they impact your operations.
                </li>
              </ul>
            </li>
            <li>
              Network Security and Firewall Management{" "}
              <ul>
                <li>
                  Advanced network security solutions, including firewalls,
                  intrusion detection, and intrusion prevention systems.
                </li>
                <li>
                  Regular monitoring and updates to protect against unauthorized
                  access and external threats.
                </li>
              </ul>
            </li>
            <li>
              Endpoint Protection and Device Security
              <ul>
                <li>
                  Endpoint security solutions to secure devices, from laptops to
                  mobile devices, against malware and unauthorized access.
                </li>
                <li>
                  Centralized management of endpoint security policies for
                  consistent, organization-wide protection.
                </li>
              </ul>
            </li>
            <li>
              Identity and Access Management (IAM){" "}
              <ul>
                <li>
                  Multi-factor authentication, role-based access controls, and
                  single sign-on (SSO) solutions to safeguard user identity.
                </li>
                <li>
                  Robust access management to enforce least privilege and
                  protect sensitive data.
                </li>
              </ul>
            </li>
            <li>
              Cloud Security Solutions
              <ul>
                <li>
                  Secure cloud infrastructure to protect data and applications
                  in cloud environments, ensuring compliance with industry
                  standards.
                </li>
                <li>
                  Continuous monitoring and management of cloud security to
                  prevent data leaks and unauthorized access.
                </li>
              </ul>
            </li>
            <li>
              Compliance and Regulatory Support
              <ul>
                <li>
                  Assistance with meeting industry compliance standards like
                  GDPR, HIPAA, PCI-DSS, and ISO.
                </li>
                <li>
                  Documentation, reporting, and security audits to ensure
                  ongoing regulatory alignment.
                </li>
              </ul>
            </li>
          </ol>
          <h3>Why Choose Our Cybersecurity Services?</h3>
          <ul>
            <li>
              Consultants trained and experienced in all Cyber Security Services{" "}
            </li>
            <li>
              Experienced resources, Mix of technical and functional profiles
            </li>
            <li>External attack measures</li>
            <li>Internal fraud measures for Business-Specific Solutions</li>
            <li>VPN and Cloud Security Connectivity</li>
          </ul>
          <ul>
            <li>
              <strong>Certified Cybersecurity Experts</strong>: A team of
              certified professionals with expertise in the latest cybersecurity
              frameworks and practices.
            </li>
            <li>
              <strong>Customized Solutions</strong>: Tailored cybersecurity
              strategies that align with your unique business requirements and
              risk profile.
            </li>
            <li>
              <strong>Proactive Protection</strong>: Advanced threat
              intelligence and real-time monitoring to stay ahead of emerging
              threats.
            </li>
          </ul>
          <p>
            Our Cybersecurity solutions offer comprehensive protection, enabling
            you to focus on your core business with the confidence that your
            digital assets are secure.{" "}
            <a href="index.html#contact">Contact Us</a> today to strengthen your
            cybersecurity defenses and secure a safer future for your
            organization.
          </p>
        </div>
      </div>
    </div>
    <section className="portfolio section-padding" id="portfolio">
      <div className="row">
        <div className="section-title  wow zoomIn">
          <div className="indented">
            <section>
              <h2>Security Operations Center (SOC/NOC) as a Service</h2>
              <p>
                Protect your organization from evolving cyber threats with our{" "}
                <strong>Security Operations Center (SOC/NOC)</strong> as a
                Service. Our SOC/NOC solutions provide around-the-clock
                monitoring, advanced threat detection, and rapid incident
                response to safeguard your digital assets and ensure compliance
                with industry standards. By leveraging the latest in
                cybersecurity technology, we offer scalable and cost-effective
                protection tailored to meet the demands of modern IT
                infrastructure.
              </p>
              <h3>Our SOC/NOC Service Offerings</h3>
              <ol>
                <li>
                  Continuous Threat Monitoring and Detection
                  <ul>
                    <li>
                      24/7 real-time monitoring of your systems and networks to
                      identify potential threats.
                    </li>
                    <li>
                      Advanced threat intelligence tools to detect suspicious
                      activities and anomalies.
                    </li>
                  </ul>
                </li>
                <li>
                  Incident Response and Remediation
                  <ul>
                    <li>
                      Swift response to security incidents to contain and
                      eliminate threats.
                    </li>
                    <li>
                      Comprehensive investigation and root cause analysis to
                      prevent future breaches.
                    </li>
                  </ul>
                </li>
                <li>
                  Vulnerability Management and Risk Assessment
                  <ul>
                    <li>
                      Regular vulnerability scans and assessments to identify
                      security gaps.
                    </li>
                    <li>
                      Prioritization and mitigation recommendations to minimize
                      risk exposure.
                    </li>
                  </ul>
                </li>
                <li>
                  Managed SIEM (Security Information and Event Management)
                  <ul>
                    <li>
                      Collection, analysis, and correlation of security events
                      across your environment.
                    </li>
                    <li>
                      Actionable insights provided through a centralized
                      dashboard for proactive security management.
                    </li>
                  </ul>
                </li>
                <li>
                  Endpoint Detection and Response (EDR)
                  <ul>
                    <li>
                      Endpoint security solutions to detect, investigate, and
                      respond to threats on devices.
                    </li>
                    <li>
                      Enhanced endpoint visibility and control, minimizing the
                      risk of compromise.
                    </li>
                  </ul>
                </li>
                <li>
                  Compliance and Reporting
                  <ul>
                    <li>
                      Ensure alignment with regulatory standards like GDPR,
                      HIPAA, and ISO.
                    </li>
                    <li>
                      Detailed security reports and dashboards for audits and
                      compliance tracking.
                    </li>
                  </ul>
                </li>
              </ol>
              <h3>Why Choose Our SOC/NOC as a Service?</h3>
              <ul>
              <li>
                <strong>Expert Cybersecurity Team</strong>: Certified security
                analysts with years of experience in threat management.
              </li>
              <li>
                <strong>Advanced Threat Intelligence</strong>: Leverage
                up-to-date threat intelligence to stay ahead of emerging risks.
              </li>
              <li>
                <strong>Scalable Solutions</strong>: Customized SOC/NOC
                solutions that grow with your business needs.
              </li>
              <li>
                <strong>Cost-Efficient</strong>: Reduce the expense of building
                and maintaining an in-house SOC/NOC.
              </li>
              </ul>
              <p>
                Our SOC/NOC as a Service empowers you to focus on your business,
                knowing that your systems, data, and users are secure.{" "}
                <a href="index.html#contact">Contact Us</a> today to discover
                how our SOC/NOC solutions can enhance your security posture and
                resilience.
              </p>
              <h3>Benefits of outsourcing security functions to a SOC/NOC</h3>
              <ol>
                <li>
                  24/7 Monitoring and Rapid Incident Response
                  <ul>
                    <li>
                      SOC/NOC providers offer round-the-clock security
                      monitoring, ensuring that potential threats are detected
                      immediately, even after business hours.
                    </li>
                    <li>
                      Immediate incident response from trained security experts
                      reduces the time from detection to resolution, minimizing
                      damage and downtime.
                    </li>
                  </ul>
                </li>
                <li>
                  Access to Advanced Security Tools and Expertise
                  <ul>
                    <li>
                      SOC/NOC providers bring specialized expertise and tools
                      for threat detection, analysis, and response, which can be
                      cost-prohibitive for in-house setups.
                    </li>
                    <li>
                      Outsourced SOC/NOCs employ certified analysts who stay
                      current with the latest cyber threats and mitigation
                      techniques, bringing unparalleled expertise to your
                      security efforts.
                    </li>
                  </ul>
                </li>
                <li>
                  Cost Savings and Resource Efficiency
                  <ul>
                    <li>
                      Outsourcing eliminates the costs of recruiting, training,
                      and retaining an in-house security team, along with
                      purchasing and maintaining advanced tools.
                    </li>
                    <li>
                      SOC/NOC as a Service models offer scalable pricing,
                      allowing companies to pay only for the level of service
                      they need, making it ideal for organizations of any size.
                    </li>
                  </ul>
                </li>
                <li>
                  Enhanced Threat Intelligence
                  <ul>
                    <li>
                      Outsourced SOC/NOCs aggregate threat intelligence from
                      multiple clients, providing deeper insights into emerging
                      trends and enabling proactive security measures.
                    </li>
                    <li>
                      By leveraging shared knowledge and real-time threat
                      intelligence, SOC/NOC providers can quickly identify
                      patterns, allowing for faster prevention and response.
                    </li>
                  </ul>
                </li>
                <li>
                  Improved Compliance and Reporting
                  <ul>
                    <li>
                      SOC/NOC providers assist in maintaining compliance with
                      industry regulations and standards such as GDPR, HIPAA,
                      PCI-DSS, and ISO.
                    </li>
                    <li>
                      Comprehensive reporting and logging ensure that your
                      organization is audit-ready, with accurate documentation
                      and insight into security practices.
                    </li>
                  </ul>
                </li>
                <li>
                  Scalability and Flexibility
                  <ul>
                    <li>
                      SOC/NOC as a Service can easily scale with business
                      growth, adapting to the changing security needs of the
                      organization.
                    </li>
                    <li>
                      As your company expands or as regulatory requirements
                      evolve, SOC/NOC providers can adjust services to meet
                      increased demand or new compliance needs.
                    </li>
                  </ul>
                </li>
                <li>
                  Focus on Core Business Functions
                  <ul>
                    <li>
                      By outsourcing security functions, internal teams can
                      focus on core business initiatives without being bogged
                      down by time-consuming security management.
                    </li>
                    <li>
                      Reduced burden on IT and management staff allows for
                      greater productivity in other critical areas of the
                      business.
                    </li>
                  </ul>
                </li>
              </ol>
              <p>
                Outsourcing to a SOC/NOC provides robust protection while
                reducing the complexity and cost of managing security in-house,
                allowing businesses to stay secure, compliant, and focused on
                growth.
              </p>
            </section>
            
          </div>
        </div>
      </div>
    </section>
    </section>
    </div>
 
</>

    );
};

export default Cyber