import React from 'react';
import Navbar from "./Navbar";

const Header = () => {
    return (
      <>
      <div className="container-fluid topbar px-20 d-none d-lg-block">
          <div className="container-fluid px-20">
            <div className="row gx-0 align-items-center" style={{ height: 45 }}>
              <div className="col-lg-8 text-center text-lg-start mb-lg-0">
                <div className="d-flex flex-wrap">
                  <a href="tel:+18322258898" className="text-light me-4">
                    <i className="fas fa-phone-alt text-primary me-2" />
                    (+1) 832-225-8898
                  </a>
                  <a href="mailto:info@celersoft.com" className="text-light me-0">
                    <i className="fas fa-envelope text-primary me-2" />
                    info@celersoft.com
                  </a>
                </div>
              </div>
              <div className="col-lg-4 text-center text-lg-end">
                <div className="d-flex align-items-center justify-content-end">
                  <a
                    href="/"
                    className="btn btn-primary btn-square rounded-circle nav-fill me-3"
                  >
                    <i className="fab fa-facebook-f text-white" />
                  </a>
                  <a
                    href="/"
                    className="btn btn-primary btn-square rounded-circle nav-fill me-3"
                  >
                    X
                  </a>
                  <a
                    href="/"
                    className="btn btn-primary btn-square rounded-circle nav-fill me-0"
                  >
                    <i className="fab fa-linkedin-in text-white" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="container-fluid sticky-top px-0">
          <div
            className="position-absolute bg-dark"
            style={{ left: 0, top: 0, width: "100%", height: "100%" }}
          ></div>
          <div className="container-fluid px-0">
            <nav className="navbar navbar-expand-lg navbar-dark pb-4 px-4">
              <a href="/" className="navbar-brand p-0">
                <img src="assets/img/logofooter.png" />

              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarCollapse"
              >
                <span className="fa fa-bars" />
              </button>
              <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav ms-auto py-0">
                <Navbar />                 
                </div>
                <div className="d-flex align-items-center flex-nowrap pt-xl-0">
                </div>
              </div>
            </nav>
          </div>
        </div>

        <div
          className="modal fade"
          id="searchModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content rounded-0">
              <div className="modal-header">
                <h4 className="modal-title mb-0" id="exampleModalLabel">
                  Search by keyword
                </h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body d-flex align-items-center">
                <div className="input-group w-75 mx-auto d-flex">
                  <input
                    type="search"
                    className="form-control p-3"
                    placeholder="keywords"
                    aria-describedby="search-icon-1"
                  />
                  <span id="search-icon-1" className="input-group-text p-3">
                    <i className="fa fa-search" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
      
    );
};

export default Header