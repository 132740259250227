function Micro() {
    return (
      <>
 
{/* Header Start */}
<div className="container-fluid bg-breadcrumb">
<div className="bg-breadcrumb-single" />
<div className="container text-center py-5" style={{ maxWidth: 900 }}>
  <h4
    className="text-white display-4 mb-4 wow fadeInDown"
    data-wow-delay="0.1s"
  >
    Micro Services
  </h4>
 
</div>
</div>
<div className="container">
<section className="portfolio section-padding" id="portfolio">
  <div className="row text-left">
    <div className="col-lg-12 col-sm-12 col-xs-12">
      <div className="section-title  wow zoomIn">
        <div className="indented">
          <h2>Microservices Development</h2>
          <section>
            <p>
              Microservices architecture is transforming how applications are
              built, scaled, and managed. By breaking down complex applications
              into modular, independent services, we help organizations unlock
              new levels of agility, efficiency, and resilience.
            </p>
            <h3>Why Choose Microservices?</h3>
            <p>
              <strong>Modular Scalability:</strong> Scale individual services
              independently to handle growing demands without impacting the
              whole application.
            </p>
            <p>
              <strong>Faster Development Cycles:</strong> Deploy and update
              specific features swiftly, keeping you ahead of market needs.
            </p>
            <p>
              <strong>Improved Reliability: </strong>Isolate services to contain
              faults and ensure other components remain operational.
            </p>
            <p>
              Our microservices solutions offer end-to-end support—from strategy
              and design to deployment and management. We use proven,
              cloud-native technologies to design resilient, lightweight
              services that work independently but integrate seamlessly for a
              cohesive user experience.{" "}
              <a href="index.html#contact">Contact Us</a> today.
            </p>
            <h3>Application Integration</h3>
            <p>
              In today's digital landscape, seamless communication between
              applications is essential for businesses looking to boost
              efficiency and adaptability. Our Application Integration services
              ensure that your systems and applications work together without
              friction, providing a streamlined flow of data and improved
              productivity across your organization.
            </p>
            <h3>Key Benefits:</h3>
            <p>
              <strong>Enhanced Connectivity:</strong> Connect diverse
              applications and platforms to improve cross-functional processes.
            </p>
            <p>
              <strong>Real-Time Data Exchange:</strong> Gain insights with
              synchronized data for timely, data-driven decisions.
            </p>
            <p>
              <strong>Scalable Solutions:</strong> Build flexible integrations
              that evolve with your business and technology needs.
            </p>
            <p>
              Our team utilizes industry-leading integration tools and platforms
              to create customized, robust solutions tailored to your
              requirements. From connecting legacy systems with modern
              applications to building unified APIs, we deliver integration
              solutions that enhance your system’s capabilities.
            </p>
          </section>
        </div>
      </div>
    </div>
  </div>
</section>
</div>

      </>
    );
};

export default Micro