function ManagedServices() {
    return (
        <>
  {/* Header Start */}
  <div className="container-fluid bg-breadcrumb">
    <div className="bg-breadcrumb-single" />
    <div className="container text-center py-5" style={{ maxWidth: 900 }}>
      <h4
        className="text-white display-4 mb-4 wow fadeInDown"
        data-wow-delay="0.1s"
      >
        Managed Services
      </h4>
     
    </div>
  </div>
  {/* Header End */}
 
  {/* Team Start */}
  <section className="container portfolio section-padding" id="portfolio">
  <div className="row text-left">
    <div className="col-lg-12 col-sm-12 col-xs-12">
      <div className="section-title  wow zoomIn">
        <div className="indented">
          <section class="portfolio  section-padding">

  <div className="container col-md-12 col-sm-4 col-xs-12">

  </div>
  <div className="col-md-12 col-sm-4 col-xs-12">
    <div className="col-md-4 col-sm-4 col-xs-12">
      <div
        className="managed_single wow fadeInRight"
        data-wow-delay="0.1s"
        data-wow-duration="1s"
        data-wow-offset={0}
      >
        <img src="assets/img/onshore.png" />
        <h4>Onshore, Nearshore and Offshore support for business continuity</h4>
      </div>
    </div>
    <div className="col-md-4 col-sm-4 col-xs-12">
      <div
        className="managed_single wow fadeInRight"
        data-wow-delay="0.1s"
        data-wow-duration="1s"
        data-wow-offset={0}
      >
        <img src="assets/img/it-automation.png" />
        <h4>IT Automation</h4>
      </div>
    </div>
    <div className="col-md-4 col-sm-4 col-xs-12">
      <div
        className="managed_single wow fadeInRight"
        data-wow-delay="0.1s"
        data-wow-duration="1s"
        data-wow-offset={0}
      >
        <img src="assets/img/priority-support.png" />
        <h4>Priority Support</h4>
      </div>
    </div>
  </div>
  <div className="col-md-12 col-sm-4 col-xs-12">
    <div className="col-md-4 col-sm-4 col-xs-12">
      <div
        className="managed_single wow fadeInRight"
        data-wow-delay="0.1s"
        data-wow-duration="1s"
        data-wow-offset={0}
      >
        <img src="assets/img/network.png" />
        <h4>Full Network Maintenance</h4>
      </div>
    </div>
    <div className="col-md-4 col-sm-4 col-xs-12">
      <div
        className="managed_single wow fadeInRight"
        data-wow-delay="0.1s"
        data-wow-duration="1s"
        data-wow-offset={0}
      >
        <img src="assets/img/simplified-billing.png" />
        <h4>Simplified Billing</h4>
      </div>
    </div>
    <div className="col-md-4 col-sm-4 col-xs-12">
      <div
        className="managed_single wow fadeInRight"
        data-wow-delay="0.1s"
        data-wow-duration="1s"
        data-wow-offset={0}
      >
        <img src="assets/img/it-consulting.png" />
        <h4>IT Consulting &amp; Strategy</h4>
      </div>
    </div>
  </div>
  <div className="col-md-12 col-sm-4 col-xs-12">
    <div className="col-md-4 col-sm-4 col-xs-12">
      <div
        className="managed_single wow fadeInRight"
        data-wow-delay="0.1s"
        data-wow-duration="1s"
        data-wow-offset={0}
      >
        <img src="assets/img/documentation.png" />
        <h4>Documentation &amp; Inventory</h4>
      </div>
    </div>
    <div className="col-md-4 col-sm-4 col-xs-12">
      <div
        className="managed_single wow fadeInRight"
        data-wow-delay="0.1s"
        data-wow-duration="1s"
        data-wow-offset={0}
      >
        <img src="assets/img/documentation.png" />
        <h4>ERP Solutions (SAP and Oracle)</h4>
      </div>
    </div>
  </div>
  </section>
  <section>
  <div className="col-md-12 col-sm-4 col-xs-12" style={{ paddingTop: 40 }}>
    
    <div className="col-md-6 col-sm-6 col-xs-12">
      <div
        className="supported wow fadeInRight"
        data-wow-delay="0.1s"
        data-wow-duration="1s"
        data-wow-offset={0}
      >
        <h4>Project Management Support</h4>
        <ul>
          <li>Framework for knowledge management </li>
          <li>Methodologies and artifacts for accelerated execution </li>
          <li>Infrastructure for solution evaluation and pre-validation</li>
          <li>Implementation of best practices for scalable solutions</li>
        </ul>
      </div>
    </div>
    <div className="col-md-6 col-sm-6 col-xs-12">
      <div
        className="supported wow fadeInRight"
        data-wow-delay="0.1s"
        data-wow-duration="1s"
        data-wow-offset={0}
      >
        
        <h4>Industries Supported</h4>
        <ul>
          <li>Banking, Mortgage and Insurance</li>
          <li>Wireless and Tele Communications &amp; Media</li>
          <li>Hi-tech &amp; Manufacturing</li>
          <li>Logistics &amp; Supply chain</li>
          <li>Pharmaceuticals</li>
          <li>Life Sciences &amp; Healthcare &nbsp;</li>
          <li>Oil and gas&nbsp;</li>
        </ul>
      </div>
    </div>  
  </div>
</section>


<section>
  <div className="col-md-12 col-sm-4 col-xs-12" style={{ paddingTop: 40 }}>
  <h3>Elements of our Managed Services Practice</h3>
  <ol type={1}>
            <li>
              Proactive Monitoring and Maintenance:
              <ul>
                <li>
                Continuous system oversight to identify and resolve issues before they impact operations.
                </li>
              </ul>
            </li>
            <li>
              Service Level Agreements (SLAs):
              <ul>
                <li>
                Clearly defined metrics ensuring accountability and meeting client performance expectations.
                </li>
              </ul>
            </li>
            <li>
            Scalable Infrastructure Management:
              <ul>
                <li>
                Flexible solutions that adapt to evolving business demands and growth.
                </li>
              </ul>
            </li>
            <li>
              Comprehensive Support Services:
              <ul>
                <li>
                24/7 helpdesk, troubleshooting, and end-user support to ensure seamless operations.
                </li>
              </ul>
            </li>
            <li>
                Expertise in Multi-Vendor Environments:
              <ul>
                <li>
                Integration and management of diverse technologies across platforms.
                </li>
              </ul>
            </li>
            <li>
                Continuous Optimization and Reporting:
              <ul>
                <li>
                Regular performance analysis, cost efficiency assessments, and system upgrades for ongoing improvement.
                </li>
              </ul>
            </li>
          </ol>
  </div>
</section>
</div>
      </div>
    </div>
  </div>
</section>
</>   
      
    );
};

export default ManagedServices