import React from "react";
import Carousel from 'react-multi-carousel';
import Carousel2 from 'react-multi-carousel';
import Carousel3 from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};
const responsive2 = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 4 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};
function Home() {
    return (
        <>
        
        <div id="carouselExampleCaptions" className="carousel slide" data-carousel-init data-ride="carousel">
    <div className="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={1} aria-label="Slide 2" />
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={2} aria-label="Slide 3" />
    </div>
    <div className="carousel-inner">
        <div className="carousel-item active">
            <img src="assets/img/slide1.jpg" className="d-block w-100" alt="..." />
            <div className="carousel-caption d-none d-md-block">
                <h3>Infrastructure Solutions</h3>
                <p>Cloud Infrastructure solutions,
                    <br />Physical Infrastructure</p>
            </div>
        </div>
        <div className="carousel-item">
            <img src="assets/img/slide2.jpg" className="d-block w-100" alt="..." />
            <div className="carousel-caption d-none d-md-block">
                <h3> Business Analytics</h3>
                <p>The various data and analytical methods help business in taking effective C-Suit decisions</p>
            </div>
        </div>
        <div className="carousel-item">
            <img src="assets/img/slide4.jpg" className="d-block w-100" alt="..." />
            <div className="carousel-caption d-none d-md-block">
                <h3>Cyber Security</h3>
                <p>Continuously assess and mitigate risk from any cyber attacks, devices, users and appliactions</p>
            </div>
        </div>
    </div>
    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true" />
        <span className="visually-hidden">Previous</span>
    </button>
    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true" />
        <span className="visually-hidden">Next</span>
    </button>
</div>


<div className="container-fluid about bg-light py-3">
    <div className="container py-3">
        <div className="row g-5 align-items-center">
            {/*
            <div className="col-lg-6 col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
                <div className="about-img">
                    <img src="assets/img/about.png" className="img-fluid w-100 rounded-top bg-white" alt="Image" />
                </div>
            </div> */}
            <div className="col-lg-12 col-xl-12 wow fadeInRight" data-wow-delay="0.3s">
                <h2 className="text-primary">CELER SOFT delivers IT Services</h2> {/*
                <h1 className="display-5 mb-4">c DELIVER IT SERVICES</h1> */}
                <p className="text ps-4 mb-4">
                    ...with a special focus on innovation and adopting excellent delivery practices. Our offerings span business and technology consulting, application services, systems integration, product engineering, custom software development, maintenance, and quality
                    assurance services.
                </p>
                <div className="row g-4 justify-content-between mb-2">
                    <div className="col-lg-6 col-xl-6">
                        <p className="text-dark">
                            <i className="fas fa-check-circle text-primary me-1" /> Strategy &amp; Consulting
                        </p>
                        <p className="text-dark mb-0">
                            <i className="fas fa-check-circle text-primary me-1" /> Business Process
                        </p>
                    </div>
                    <div className="col-lg-6 col-xl-6">
                        <p className="text-dark">
                            <i className="fas fa-check-circle text-primary me-1" />{" "} Marketing Rules
                        </p>
                        <p className="text-dark mb-0">
                            <i className="fas fa-check-circle text-primary me-1" />{" "} Partnerships
                        </p>
                    </div>
                </div>
                {/*
                <div className="row g-4 justify-content-between mb-5">
                    <div className="col-xl-5">
                        <a href="/" className="btn btn-primary rounded-pill py-3 px-5">
                      Discover More
                    </a>
                    </div>
                    <div className="col-xl-7 mb-5">
                        <div className="about-customer d-flex position-relative">
                            <img src="assets/img/customer-img-1.jpg" className="img-fluid btn-xl-square position-absolute" style={{ left: 0, top: 0 }} alt="Image" />
                            <img src="assets/img/customer-img-2.jpg" className="img-fluid btn-xl-square position-absolute" style={{ left: 45, top: 0 }} alt="Image" />
                            <img src="assets/img/customer-img-3.jpg" className="img-fluid btn-xl-square position-absolute" style={{ left: 90, top: 0 }} alt="Image" />
                            <img src="assets/img/customer-img-1.jpg" className="img-fluid btn-xl-square position-absolute" style={{ left: 135, top: 0 }} alt="Image" />
                            <div className="position-absolute text-dark" style={{ left: 220, top: 10 }}>
                                <p className="mb-0">5m+ Trusted</p>
                                <p className="mb-0">Global Customers</p>
                            </div>
                        </div>
                    </div>
                </div> */}

            </div>
            {/*
            <div className="col-lg-6 col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
                <div className="row g-4 text-center align-items-center justify-content-center">
                    <div className="col-sm-4">
                        <div className="bg-primary rounded p-4">
                            <div className="d-flex align-items-center justify-content-center">
                                <span className="counter-value fs-1 fw-bold text-dark" data-toggle="counter-up">
                          200
                        </span>
                                <h4 className="text-dark fs-1 mb-0" style={{ fontWeight: 600, fontSize: 25 }}>
                          +
                        </h4>
                            </div>
                            <div className="w-100 d-flex align-items-center justify-content-center">
                                <p className="text-white mb-0">Projects Completed</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="bg-dark rounded p-4">
                            <div className="d-flex align-items-center justify-content-center">
                                <span className="counter-value fs-1 fw-bold text-white" data-toggle="counter-up">
                          21
                        </span>
                                <h4 className="text-white fs-1 mb-0" style={{ fontWeight: 600, fontSize: 25 }}>
                          +
                        </h4>
                            </div>
                            <div className="w-100 d-flex align-items-center justify-content-center">
                                <p className="mb-0">Clients</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="bg-primary rounded p-4">
                            <div className="d-flex align-items-center justify-content-center">
                                <span className="counter-value fs-1 fw-bold text-dark" data-toggle="counter-up">
                          57
                        </span>
                                <h4 className="text-dark fs-1 mb-0" style={{ fontWeight: 600, fontSize: 25 }}>
                          +
                        </h4>
                            </div>
                            <div className="w-100 d-flex align-items-center justify-content-center">
                                <p className="text-white mb-0">Team Members</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

        </div>
    </div>
</div>

<div className="container-fluid service py-3">
    <div className="container py-2">
        <div className="text-center mx-auto pb-3 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: 800 }}>
            <h2 className="text-primary">SERVICES WE PROVIDE</h2>
        </div>
        <div className="row pb-4 g-3 justify-content-center text-center">
            <div className="col-md-6 col-lg-4 col-xl-4 wow fadeInUp" data-wow-delay="0.1s">
                <div className="service-item bg-light rounded">
                    <div className="service-img">
                        <img src="assets/img/infrastructure.jpg" className="img-fluid w-100 rounded-top" alt="" />
                    </div>
                    <div className="service-content text-center p-4">
                        <div className="service-content-inner">
                            <a href="/infrastructure" className="h4 mb-2 d-inline-flex text-start">
                                <i className="fas fa-donate fa-2x me-2" /> Infrastructure
                                <br /> Solutions
                            </a>
                            <p className="mb-4">
                                Empower your business with robust, scalable, and secure infrastructure solutions designed to support today’s digital...
                            </p>
                            <a className="btn btn-light rounded-pill py-2 px-4" href="/infrastructure">
                        Read More
                      </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-4 wow fadeInUp" data-wow-delay="0.3s">
                <div className="service-item bg-light rounded">
                    <div className="service-img">
                        <img src="assets/img/business.jpg" className="img-fluid w-100 rounded-top" alt="" />
                    </div>
                    <div className="service-content text-center p-4">
                        <div className="service-content-inner">
                            <a href="/business" className="h4 mb-2 d-inline-flex text-start">
                                <i className="fas fa-donate fa-2x me-2" /> Business
                                <br />Analytics
                            </a>
                            <p className="mb-4">
                                Unlock powerful insights and drive smarter decision-making with our Business Analytics solutions. We specialize ...
                            </p>
                            <a className="btn btn-light rounded-pill py-2 px-4" href="/business">
                        Read More
                      </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-4 wow fadeInUp" data-wow-delay="0.5s">
                <div className="service-item bg-light rounded">
                    <div className="service-img">
                        <img src="assets/img/erp.jpg" className="img-fluid w-100 rounded-top" alt="" />
                    </div>
                    <div className="service-content text-center p-4">
                        <div className="service-content-inner">
                            <a href="/erp" className="h4 mb-2 d-inline-flex text-start">
                                <i className="fas fa-donate fa-2x me-2" /> ERP
                                <br />Solutions
                            </a>
                            <p className="mb-4">
                                Streamline, innovate, and scale your business operations with our expert SAP services. Our team specializes...
                            </p>
                            <a className="btn btn-light rounded-pill py-2 px-4" href="/erp">
                        Read More
                      </a>
                        </div>
                    </div>
                </div>
            </div>


            {/*
            <div className="col-12">
                <a className="btn btn-primary rounded-pill py-3 px-5 wow fadeInUp" data-wow-delay="0.1s" href="/">
                  Services More
                </a>
            </div> */}
        </div>
        <div className="row g-3 justify-content-center text-center">
            <div className="col-md-6 col-lg-4 col-xl-4 wow fadeInUp" data-wow-delay="0.7s">
                <div className="service-item bg-light rounded">
                    <div className="service-img">
                        <img src="assets/img/cyber.jpg" className="img-fluid w-100 rounded-top" alt="" />
                    </div>
                    <div className="service-content text-center p-4">
                        <div className="service-content-inner">
                            <a href="/cyber" className="h4 mb-2 d-inline-flex text-start">
                                <i className="fas fa-donate fa-2x me-2" /> Cyber
                                <br />Security
                            </a>
                            <p className="mb-4">
                                In today’s digital landscape, robust cybersecurity is essential for safeguarding sensitive data and ensuring business...
                            </p>
                            <a className="btn btn-light rounded-pill py-2 px-4" href="/cyber">
                        Read More
                      </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-4 wow fadeInUp" data-wow-delay="0.1s">
                <div className="service-item bg-light rounded">
                    <div className="service-img">
                        <img src="assets/img/micro.jpg" className="img-fluid w-100 rounded-top" alt="" />
                    </div>
                    <div className="service-content text-center p-4">
                        <div className="service-content-inner">
                            <a href="/micro" className="h4 mb-2 d-inline-flex text-start">
                                <i className="fas fa-donate fa-2x me-2" /> Micro
                                <br />Services
                            </a>
                            <p className="mb-4">
                                Microservices architecture is transforming how applications are built, scaled, and managed. By breaking down...
                            </p>
                            <a className="btn btn-light rounded-pill py-2 px-4" href="/micro">
                        Read More
                      </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-4 wow fadeInUp" data-wow-delay="0.3s">
                <div className="service-item bg-light rounded">
                    <div className="service-img">
                        <img src="assets/img/it.jpg" className="img-fluid w-100 rounded-top" alt="" />
                    </div>
                    <div className="service-content text-center p-4">
                        <div className="service-content-inner">
                            <a href="/It" className="h4 mb-2 d-inline-flex text-start">
                                <i className="fas fa-donate fa-2x me-2" /> IT
                                <br />Staffing
                            </a>
                            <p className="mb-4">
                                Our IT recruitment services connect you with top talent, handpicked to drive your business forward. In today’s...
                            </p>
                            <a className="btn btn-light rounded-pill py-2 px-4" href="/It">
                        Read More
                      </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
{/* <div className="container-fluid project">
    <div className="container-fluid">
        <div className="text-center mx-auto pb-3 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: 800 }}>
            <h1 className="dispay-4">Case Studies</h1>
        </div>
        <div className="project-carouel owl-arousel ow fdeInUp" data-wow-delay="0.1s">

            <Carousel swipeable={true} draggable={true} showDots={true} responsive={responsive} ssr={true} infinite={true} autoPlay={true} autoPlaySpeed={8000} keyBoardControl={true} customTransition="all .5" transitionDuration={8000} containerClass="carousel-container"
            removeArrowOnDeviceType={[ "tablet", "mobile"]} deviceType={[ "desktop", "tablet", "mobile"]} dotListClass="custom-dot-list-style" itemClass="carousel-item-padding-40-px">
                <div className="projep">
                    <div className="project-img">
                        <img src="assets/img/dataflow-case-study.jpg" className="img-flid w-100 rouned" alt="Image" />
                    </div>
                    <div className="project-connt bg-ligt roundd p-4">
                        <div className="project-content-inner">
                            <div className="project-icon mb-3">
                                <i className="fas fa-chart-line fa-4x text-primary" />
                            </div>
                            <p className="text-drk fs-5 b-3">Data flow management</p>
                            <a href="/" className="h4">
                      Optimize resource utilization by moving data between data
                      centers or between on-premises infrastructure and cloud
                      infrastructure...
                    </a>
                            <div className="pt-4">
                                <a className="btn btn-light rounded-pill py-3 px-5" href="/">
                        Read More
                      </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="project-item h-100 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="project-img">
                        <img src="assets/img/cloud-case-study.jpg" className="img-fluid w-100 rounded" alt="Image" />
                    </div>
                    <div className="project-content bg-light rounded p-4">
                        <div className="project-content-inner">
                            <div className="project-icon mb-3">
                                <i className="fas fa-chart-line fa-4x text-primary" />
                            </div>
                            <p className="text-dark fs-5 mb-3">Cloud Migration</p>
                            <a href="/" className="h4">
                    Because the continuous replication occurs in the background, with no performance disruption, the actual cutover usually takes...
                    </a>
                            <div className="pt-4">
                                <a className="btn btn-light rounded-pill py-3 px-5" href="/">
                        Read More
                      </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="project-item h-100 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="project-img">
                        <img src="assets/img/sap-case-study.jpg" className="img-fluid w-100 rounded" alt="Image" />
                    </div>
                    <div className="project-content bg-light rounded p-4">
                        <div className="project-content-inner">
                            <div className="project-icon mb-3">
                                <i className="fas fa-chart-line fa-4x text-primary" />
                            </div>
                            <p className="text-dark fs-5 mb-3">ERP Solutions</p>
                          <a href="/" className="h4">
                          Streamline, innovate, and scale your business operations with our expert SAP services. Our team specializes in leveraging SAP’s powerful...
                          </a>
                            <div className="pt-4">
                                <a className="btn btn-light rounded-pill py-3 px-5" href="/erp-case-studies">
                        Read More
                      </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="project-item h-100 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="project-img">
                        <img src="assets/img/projects-1.jpg" className="img-fluid w-100 rounded" alt="Image" />
                    </div>
                    <div className="project-content bg-light rounded p-4">
                        <div className="project-content-inner">
                            <div className="project-icon mb-3">
                                <i className="fas fa-chart-line fa-4x text-primary" />
                            </div>
                            <p className="text-dark fs-5 mb-3">Data flow management 4</p>
                            <a href="/" className="h4">
                      Optimize resource utilization by moving data between data
                      centers or between on-premises infrastructure and cloud
                      infrastructure...
                    </a>
                            <div className="pt-4">
                                <a className="btn btn-light rounded-pill py-3 px-5" href="/">
                        Read More
                      </a>
                            </div>
                        </div>
                    </div>
                </div>
            </Carousel>
        </div>
    </div>
</div> */}


<div className="container-fluid testimonial bg-light py-3">
    <div className="container py-3">
        <div className="row g-4 align-items-center">
            {/* <div className="col-xl-12 wow fadeInLeft" data-wow-delay="0.1s">
                <div className="h-100 rounded">
                    <h4 className="text-primary">Testimonials </h4>
                    <h1 className="display-4 mb-4">Clients are Talking</h1>
                    <p className="mb-4">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum atque soluta unde itaque. Consequatur quam odit blanditiis harum veritatis porro.
                    </p>
                    <a className="btn btn-primary rounded-pill text-white py-3 px-5" href="/">
                    Read All Reviews <i className="fas fa-arrow-right ms-2" />
                  </a>
                </div>
            </div>
            <div className="col-xl-12">

                <Carousel2 responsive={responsive}>

                    <div className="testimonial-item bg-white rounded p-4 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="d-flex">
                            <div>
                                <i className="fas fa-quote-left fa-3x text-dark me-3" />
                            </div>
                            <p className="mt-4">
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Magnam eos impedit eveniet dolorem culpa ullam incidunt vero quo recusandae nemo? Molestiae doloribus iure,
                            </p>
                        </div>
                        <div className="d-flex justify-content-end">
                            <div className="my-auto text-end">
                                <h5>Person Name</h5>
                                <p className="mb-0">Profession</p>
                            </div>
                            <div className="bg-white rounded-circle ms-3">
                                <img src="assets/img/testimonial-1.jpg" className="rounded-circle p-2" style={{ width: 80, height: 80, border: "1px solid", borderColor: "var(--bs-primary)" }} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="testimonial-item bg-white rounded p-4 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="d-flex">
                            <div>
                                <i className="fas fa-quote-left fa-3x text-dark me-3" />
                            </div>
                            <p className="mt-4">
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Magnam eos impedit eveniet dolorem culpa ullam incidunt vero quo recusandae nemo? Molestiae doloribus iure,
                            </p>
                        </div>
                        <div className="d-flex justify-content-end">
                            <div className="my-auto text-end">
                                <h5>Person Name</h5>
                                <p className="mb-0">Profession</p>
                            </div>
                            <div className="bg-white rounded-circle ms-3">
                                <img src="assets/img/testimonial-2.jpg" className="rounded-circle p-2" style={{ width: 80, height: 80, border: "1px solid", borderColor: "var(--bs-primary)" }} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="testimonial-item bg-white rounded p-4 wow fadeInUp" data-wow-delay="0.7s">
                        <div className="d-flex">
                            <div>
                                <i className="fas fa-quote-left fa-3x text-dark me-3" />
                            </div>
                            <p className="mt-4">
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Magnam eos impedit eveniet dolorem culpa ullam incidunt vero quo recusandae nemo? Molestiae doloribus iure,
                            </p>
                        </div>
                        <div className="d-flex justify-content-end">
                            <div className="my-auto text-end">
                                <h5>Person Name</h5>
                                <p className="mb-0">Profession</p>
                            </div>
                            <div className="bg-white rounded-circle ms-3">
                                <img src="assets/img/testimonial-3.jpg" className="rounded-circle p-2" style={{ width: 80, height: 80, border: "1px solid", borderColor: "var(--bs-primary)" }} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="testimonial-item bg-white rounded p-4 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="d-flex">
                            <div>
                                <i className="fas fa-quote-left fa-3x text-dark me-3" />
                            </div>
                            <p className="mt-4">
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Magnam eos impedit eveniet dolorem culpa ullam incidunt vero quo recusandae nemo? Molestiae doloribus iure,
                            </p>
                        </div>
                        <div className="d-flex justify-content-end">
                            <div className="my-auto text-end">
                                <h5>Person Name</h5>
                                <p className="mb-0">Profession</p>
                            </div>
                            <div className="bg-white rounded-circle ms-3">
                                <img src="assets/img/testimonial-1.jpg" className="rounded-circle p-2" style={{ width: 80, height: 80, border: "1px solid", borderColor: "var(--bs-primary)" }} alt="" />
                            </div>
                        </div>
                    </div>
                </Carousel2>

            </div> */}

            <div className="col-xl-12">
                <div className="section-title text-center wow zoomIn clients" style={{ visibility: "visible", animationName: "zoomIn" }}>
                </div>
                <Carousel3 responsive={responsive2} infinite={true} autoPlay={true} autoPlaySpeed={3000}>




                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="https://celersoft.acetechnow.com/tophp/images/logo-1.jpg" alt="logo-1" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="https://celersoft.acetechnow.com/tophp/images/logo-2.jpg" alt="logo-2" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="https://celersoft.acetechnow.com/tophp/images/logo-3.jpg" alt="logo-3" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="https://celersoft.acetechnow.com/tophp/images/logo-4.jpg" alt="logo-4" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="https://celersoft.acetechnow.com/tophp/images/logo-5.jpg" alt="logo-5" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="https://celersoft.acetechnow.com/tophp/images/logo-6.jpg" alt="logo-6" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="https://celersoft.acetechnow.com/tophp/images/logo-7.jpg" alt="logo-7" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="https://celersoft.acetechnow.com/tophp/images/logo-8.jpg" alt="logo-8" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="https://celersoft.acetechnow.com/tophp/images/logo-9.jpg" alt="logo-9" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="https://celersoft.acetechnow.com/tophp/images/logo-10.jpg" alt="logo-10" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="https://celersoft.acetechnow.com/tophp/images/logo-11.jpg" alt="logo-11" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="https://celersoft.acetechnow.com/tophp/images/logo-12.jpg" alt="logo-12" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="https://celersoft.acetechnow.com/tophp/images/logo-13.jpg" alt="logo-13" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="https://celersoft.acetechnow.com/tophp/images/logo-14.jpg" alt="logo-14" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="https://celersoft.acetechnow.com/tophp/images/logo-15.jpg" alt="logo-15" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="https://celersoft.acetechnow.com/tophp/images/logo-16.jpg" alt="logo-16" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="https://celersoft.acetechnow.com/tophp/images/logo-17.jpg" alt="logo-17" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="https://celersoft.acetechnow.com/tophp/images/logo-18.jpg" alt="logo-18" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="https://celersoft.acetechnow.com/tophp/images/bjc.png" alt="bjc" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="https://celersoft.acetechnow.com/tophp/images/bluecross.png" alt="Blue Cross" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="https://celersoft.acetechnow.com/tophp/images/livebetter.png" alt="livebetter" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="/assets/img/al-khalili.png" alt="al-khalili" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="/assets/img/alwatania-poultry.png" alt="alwatania" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="/assets/img/atolye.png" alt="atolye" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="/assets/img/auro-realty.png" alt="auro realty" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="/assets/img/bahwan-it.png" alt="bahwan it" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="/assets/img/nigerian-national-petroleum.png" alt="nigerian national petroleum" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="/assets/img/nigerial-lng.png" alt="nigerial lng" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="/assets/img/royaloperahouse-muscat.png" alt="royaloperahouse muscat" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="/assets/img/spimaco-adwaeih.png" alt="spimaco adwaeih" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="/assets/img/omantel.png" alt="omantel" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="/assets/img/mbholding.png" alt="mbholding" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="/assets/img/mccormick.png" alt="mccormick" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="/assets/img/nga.png" alt="nga" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="/assets/img/tollplus.png" alt="tollplus" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="/assets/img/coromandel.png" alt="coromandel" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="/assets/img/club-mahindra.png" alt="club mahindra" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="/assets/img/nsl-group.png" alt="nsl group" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="/assets/img/southern-power-distribution.png" alt="southern power distribution" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="/assets/img/shreeja.png" alt="shreeja" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="/assets/img/wisys.png" alt="wisys" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="/assets/img/ramky.png" alt="ramky" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="/assets/img/highco.png" alt="highco" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="/assets/img/hf.png" alt="hf" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="/assets/img/grg-cotspin.png" alt="grg cotspin" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="/assets/img/tronox.png" alt="tronox" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="/assets/img/ntt-data.png" alt="ntt data" />{" "}
                        </div>
                    </div>
                    <div className="owl-item" style={{ width: 293 }}>
                        <div className="item">
                            {" "}
                            <img src="/assets/img/hexagon.png" alt="hexagon" />{" "}
                        </div>
                    </div>
                </Carousel3>
            </div>

        </div>
    </div>
</div>



      </>
      
      
    );
};

export default Home