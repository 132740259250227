function About() {
    return (
        <>
       
  {/* Header Start */}
  <div className="container-fluid bg-breadcrumb">
    <div className="bg-breadcrumb-single" />
    <div className="container text-center py-5" style={{ maxWidth: 900 }}>
      <h4
        className="text-white display-4 mb-4 wow fadeInDown"
        data-wow-delay="0.1s"
      >
        About Us
      </h4>
     
    </div>
  </div>
  {/* Header End */}
 
  {/* Team Start */}
  <section className="container portfolio section-padding" id="portfolio">
  <div className="row text-left">
    <div className="col-lg-12 col-sm-12 col-xs-12">
      <div className="section-title  wow zoomIn">
        <div className="indented">
          <section class="portfolio  section-padding">
          <h3>Celersoft is the right size for your project needs!</h3>
          <p>Headquarters in Houston, Texas, Celersoft is a leading IT consulting and services provider dedicated to empowering businesses with tailored technology solutions. With an extensive global reach, we offer onshore, nearshore and offshore support services to U.S.-based clients across a variety of industries. Our team brings specialized expertise in Infrastructure Solutions, Cyber Security, Business Analytics, Micro Services, ERP Solutions, Managed Services and IT Staffing </p>
          <h3>What makes Celersoft different? </h3>
          <p>Our people. We believe that our team’s dedication, skills, and values are our greatest asset in delivering transformative results while delivering exceptional quality to our clients. </p>
          <h3>Commitment to excellence:</h3>
          <p>Celersoft operates with two primary objectives: enhancing efficiency and driving productivity. We achieve this through streamlined organizational practices, a worldwide infrastructure, and a commitment to delivering measurable outcomes that reduce costs and increase operational effectiveness for our clients. Our focus on intensive recruitment, training, and retention empowers us to provide a reliable, skilled, and productive workforce that meets the evolving needs of our clients.</p>
          <h3>The Celersoft Value Add in a Digital age:</h3>
          <p>We are more than a service provider; we are a partner in your success. Our team’s specialized knowledge, experience, and dedication to quality empower businesses to thrive in a rapidly evolving digital landscape.</p>
          <ul>
            <li>Customer satisfaction with on-time and on-budget delivery models.</li>
            <li>Consulting on-demand with tailored payment options.</li>
            <li>Flexible delivery model to reduce project implementation lifecycle and lower TCO.</li>
            <li>Expertise in varied domains and industry-specific best practices.</li>
            <li>Successful application performance monitoring deployments.</li>
            <li>Robust quality assurance processes to ensure defect-free delivery.</li>
          </ul>
          <h3>Where Expertise Meets Commitment</h3>
          <p>Headquartered in Houston, Texas, Celersoft is a leading IT consulting and fortune 2000, Oil and Gas services provider dedicated to empowering businesses with tailored technology solutions. With an extensive global reach, we offer nearshore and offshore software development and support services to U.S.-based clients across a variety of industries. Our team brings specialized expertise in Artificial Intelligence (AI), Machine Learning (ML), Cloud Services, Application Development, Infrastructure Solutions, Business Analytics, Cybersecurity, and more.</p>
          <p>What makes Celersoft different? Our people. We believe that our team’s dedication, skills, and values are our greatest asset in delivering transformative results for our clients.</p>
          <h3>Our Mission</h3>
          <p>At Celersoft, we are driven by a mission to deliver the highest standard of service with the integrity, flexibility, and personalized attention of a dedicated partner. As the premier workforce advisor in the industry, we know that performance matters. We are committed to understanding and fulfilling the unique needs of every client, no matter the size or scope of their projects.</p>
          <h3>Our Story</h3>
          <p>Founded in 2007, Celersoft was built on a simple yet powerful philosophy: to connect top talent with great companies in need of specialized expertise. Initially focused on staffing solutions for the Oil & Gas industry, Technology, Accounting, Legal, and Administrative sectors, we have grown into a comprehensive provider of IT and consulting services for organizations across diverse industries.</p>
          <p>Our values are rooted in:</p>
          <ul>
              <li>Delivering exceptional quality to our clients.</li>
              <li>Treating each candidate with the utmost respect and care.</li>
              <li>Only presenting the highest-quality candidates.</li>
              <li>Acting in the best interest of both clients and candidates.</li>
              <li>Prioritizing Relationships, Reliability, and Results—our core pillars of success.</li>
          </ul>
          <h3> Our Approach: Efficiency and Productivity </h3>
          <p>Celersoft operates with two primary objectives: enhancing efficiency and driving productivity. We achieve this through streamlined organizational practices, a worldwide infrastructure, and a commitment to delivering measurable outcomes that reduce costs and increase operational effectiveness for our clients. Our focus on intensive recruitment, training, and retention empowers us to provide a reliable, skilled, and productive workforce that meets the evolving needs of our partners.</p>
          <h3>Let’s Transform Together</h3>
          <p>At Celersoft, we are more than a service provider; we are a partner in your success. Our team’s specialized knowledge, experience, and dedication to quality empower businesses to thrive in a rapidly evolving digital landscape.</p>
          <p><strong>Join us in building a more connected, efficient, and innovative future.</strong></p>
          </section>
          <section>
            <h3>Value Proposition</h3>
            <ul>
              <li>
                Customer satisfaction with on-time and on-budget delivery.
              </li>
              <li>Consulting on-demand with tailored payment options.</li>
              <li>
                Offshore delivery model to reduce project implementation
                lifecycle and lower TCO.
              </li>
              <li>
                Expertise in varied domains and industry-specific best
                practices.
              </li>
              <li>
                Successful application performance monitoring deployments.
              </li>
              <li>
                Robust quality assurance processes to ensure defect-free
                delivery.
              </li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  </div>
</section>

</>



      
      
    );
};

export default About