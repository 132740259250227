function ERPCaseStudies() {
  return (
    <>

{/* Header Start */}
<div className="container-fluid bg-breadcrumb">
<div className="bg-breadcrumb-single" />
<div className="container text-center py-5" style={{ maxWidth: 900 }}>
<h4
  className="text-white display-4 mb-4 wow fadeInDown"
  data-wow-delay="0.1s"
>
  ERP Case Studies
</h4>

</div>
</div>

{/* START PORTFOLIO */}
<div className="container pageiderp">
<section className="portfolio section-padding" id="portfolio">
  <div className="row">
    <div className="section-title  wow zoomIn">
      <div className="indented">
        <section>
          <h1>Case Study 1 – COMD Escravos LPG Sales</h1>
          <ul>
            <li>
              Gas and Condensate are by-products of the crude Oil and Natural
              Gas processing. Escravos Liquefied Petroleum Gas (LPG) is one of
              them COMD sells Escravos LPG on behalf of the Federal Government
              of Nigeria
            </li>
            <li>
              Escravos LPG is a mixture of different components for e.g.
              Ethane, Propane etc.
            </li>
            <li>
              Valuation (pricing) of Escravos LPG is based on the weight of
              the main product and volume (%) of the individual components
              within the main product
            </li>
            <li>
              The order to cash process deployed for COMD in 2014/15 deemed
              unfit to capture Escravos LPG Sales
            </li>
            <li>
              The solution deployed was resulting in price discrepancies with
              inability to calculate the unit price of product per MTO
            </li>
            <li>
              Standard SAP doesn’t allow the provision to calculate the
              pricing considering weight of main product and volume of
              individual products
            </li>
            <li>
              Business were capturing revenue directly in SAP FI module
              without using SD module to capture Nominations, Liftings and
              Invoices
            </li>
            <li>
              SAPCOE designed a new process to capture nominations, liftings
              and valuations/Invoices of Escravos LPG in SD module thus
              transferring revenue to FI module
            </li>
            <li>
              All concerns of business are addressed in the new solution
            </li>
            <li>
              All required business documents for e.g. Valuation, Invoice, DPR
              and Marketing clearances are developed to be generated from SAP
              system
            </li>
            <li>
              Status – User Acceptance Testing (UAT) completed and mail
              confirmations received from Business
            </li>
            <li>
              Go Live plan - Business to capture postings from June, 2020 in
              live server
            </li>
            <li>
              A new dashboard is designed to capture the volume percentages of
              the components of the main product with features to create, edit
              and save in the database (SAP system)
            </li>
            <li>
              A strong and robust pricing routine is designed to calculate the
              average price of the main product using the volume percentages,
              daily prices from publication and the pricing option chosen by
              customer
            </li>
          </ul>
          <img src="assets/img/lpg-sales.png" />
          <h3>Case Study 2 - Crude Oil Trading &amp; LPG Sales Process </h3>
          <img src="assets/img/lpg-sales2.png" />
          <p>
            OPERATIONS DEPARTMENT - Supplier-Input-Process-Output-Customer
            (SIPOC) model
          </p>
          <table className="casestudyoperations">
            <tbody>
              <tr>
                <td>
                  <img src="assets/img/suppliers.png" />
                  <p>Suppliers</p>
                  <ul>
                    <li>Hyson/CalsonBoard of Directors</li>
                    <li>NNPC/VITOL Top Management</li>
                    <li>COMD</li>
                    <li>PPMC</li>
                    <li>NLNG</li>
                    <li>Hyson/Calson Departments</li>
                    <li>Counterparties</li>
                  </ul>
                </td>
                <td>
                  <img src="assets/img/inputs.png" />
                  <p>Inputs</p>
                  <ul>
                    <li>Monthly crude oil allocation under term contract</li>
                    <li>LPG Allocation from PPMC</li>
                    <li>Request for DSDP product supply</li>
                    <li>DSDP Crude Allocation</li>
                    <li>LPG volume from NLNG</li>
                    <li>LPG sourcing from Algasco</li>
                    <li>Term contract acquisition</li>
                  </ul>
                </td>
                <td>
                  <img src="assets/img/processes.png" />
                  <p>Processes</p>
                  <ul>
                    <li>Crude Oil trading operations</li>
                    <li>DSDP trading operations</li>
                    <li>Domestic LPG sales operations</li>
                    <li>Trading sheet preparation </li>
                    <li>Database management</li>
                    <li>Contract management </li>
                    <li>Vessel nomination</li>
                    <li>Cargo valuation</li>
                    <li>Claims management</li>
                  </ul>
                </td>
                <td>
                  <img src="assets/img/outputs.png" />
                  <p>Outputs</p>
                  <ul>
                    <li>Reconciliation report</li>
                    <li>Payment advise</li>
                    <li>Payment receipt</li>
                    <li>ayment confirmation</li>
                    <li>Trading margin report</li>
                    <li>Business Performance report</li>
                    <li>Credit note Utilization</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            Detail work procedure - NLNG LPG DOMESTIC SALES OPERATIONS (SPA
            CONTRACT EXECUTION)
          </p>
          <table className="casestudylpg">
            <tbody>
              <tr>
                <th>S/N</th>
                <th>Tasks</th>
                <th>Steps</th>
                <th>Timeline as per SPA</th>
                <th>Task owner</th>
              </tr>
              <tr>
                <td>1</td>
                <td>
                  Receive contract start up letter from NLNG and notification
                  of quoted price factor.{" "}
                </td>
                <td>
                  <ul>
                    <li>
                      Hyson communicate Annual Contract Quantity (ACQ) to NLNG
                    </li>
                    <li>
                      Finance Prepare Performance Bond (PB) and Bank Guaranty
                      (BG) for submission to NLNG{" "}
                    </li>
                  </ul>
                </td>
                <td>Receipt of notification</td>
                <td>
                  SUPERVISOR
                  <br />
                  LPG
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>Receive LPG SPA from NLNG </td>
                <td>
                  <ul>
                    <li>
                      Review of SPA by Commercial and Legal Departments{" "}
                    </li>
                    <li>SPA Execution and Sign-off by both parties.</li>
                  </ul>
                </td>
                <td>Receipt of notification</td>
                <td>COMMERCIAL AND LEGAL DEPTS</td>
              </tr>
              <tr>
                <td>3</td>
                <td>
                  Hyson confirms Terminal User Agreement (TUA) status with
                  NPSC.{" "}
                </td>
                <td>
                  Hyson Pays Throughput Fees to NPSC and forwards Remita
                  Receipt to NPSC.
                </td>
                <td>7 days prior to the arrival of the vessel</td>
                <td>LPG SUPERVISOR AND FINANCE MANAGER</td>
              </tr>
            </tbody>
          </table>
          <img src="assets/img/casestudy-spa.png" />
          <p>
            Detail work procedure:NLNG LPG DOMESTIC SALES OPERATIONS
            (DISCHARGE AND TRUCKING)
          </p>
          <table className="casestudylpg">
            <tbody>
              <tr>
                <th>S/N</th>
                <th>Tasks</th>
                <th>Steps</th>
                <th>Timeline as per SPA</th>
                <th>Task owner</th>
              </tr>
              <tr>
                <td>1</td>
                <td>
                  LPG Off-takers appointed Agent nominates product discharge
                  window to NLNG.
                </td>
                <td>
                  <ul>
                    <li>
                      NLNG review discharge Laycan\Quantity and sends to HYSON
                      for confirmation{" "}
                    </li>
                    <li>
                      HYSON confirms quantity and NLNG sends revised DI to
                      HYSON
                    </li>
                  </ul>
                </td>
                <td>10 Days to the 2-day Window</td>
                <td>LPG SUPERVISOR</td>
              </tr>
              <tr>
                <td>2</td>
                <td>
                  LPG Off-takers appointed Agent secures berthing window from
                  Marine and Transport Det (MTD) prior to the arrival of the
                  Vessel
                </td>
                <td>
                  Approval of Berthing Window communicated to the Off-Takers.
                </td>
                <td>5 days prior to the arrival of the vessel</td>
                <td>LPG SUPERVISOR</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Vessel arrives Lagos from NLNG, Bonny</td>
                <td>
                  Vessel Berths at NOJ, BOP, PWA or NAVGAS PRIVATE JETTY and
                  commences discharge to NPSC, NIPCO OR NAVGAS storage
                  facilities, Apapa
                </td>
                <td>A day before the first day of LAYCAN/WINDOW</td>
                <td>LPG SUPERVISOR</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Product Nomination</td>
                <td>Sales/Trucking of LPG product to the Domestic Market</td>
                <td>As soon as the Vessel commences discharge operation</td>
                <td>LPG SUPERVISOR</td>
              </tr>
            </tbody>
          </table>
          <img src="assets/img/casestudy-crude.jpg" />
          <p>
            Detail work procedure:NLNG LPG DOMESTIC SALES OPERATIONS (INVOICE
            AND PAYMENTS)
          </p>
          <table className="casestudylpg">
            <tbody>
              <tr>
                <th>S/N</th>
                <th>Tasks</th>
                <th>Steps</th>
                <th>Timeline as per SPA</th>
                <th>Task owner</th>
              </tr>
              <tr>
                <td>1</td>
                <td>Hyson receives LPG invoice from NLNG</td>
                <td>
                  <ul>
                    <li>
                      NLNG review discharge Laycan\Quantity and sends to HYSON
                      for confirmation
                    </li>
                    <li>
                      HYSON confirms quantity and NLNG sends revised DI to
                      HYSON
                    </li>
                  </ul>
                </td>
                <td>10 Days to the 2-day Window</td>
                <td>LPG SUPERVISOR</td>
              </tr>
              <tr>
                <td>2</td>
                <td>
                  LPG Off-takers appointed Agent secures berthing window from
                  Marine and Transport Det (MTD) prior to the arrival of the
                  Vessel
                </td>
                <td>
                  Approval of Berthing Window communicated to the Off-Takers.
                </td>
                <td>A day before the first day of LAYCAN/WINDOW</td>
                <td>LPG SUPERVISOR</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Vessel arrives Lagos from NLNG, Bonny</td>
                <td>
                  {" "}
                  Vessel Berths at NOJ, BOP, PWA or NAVGAS PRIVATE JETTY and
                  commences discharge to NPSC, NIPCO OR NAVGAS storage
                  facilities, Apapa{" "}
                </td>
                <td>A day before the first day of LAYCAN/WINDOW</td>
                <td>LPG SUPERVISOR</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Product Nomination</td>
                <td>Sales/Trucking of LPG product to the Domestic Market</td>
                <td>As soon as the Vessel commences discharge operation</td>
                <td>LPG SUPERVISOR</td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
    </div>
  </div>
</section>
</div>

      </>
  );
};

export default ERPCaseStudies