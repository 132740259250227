// Filename - "./components/Navbar.js

import React from "react";
//import { Nav, NavLink, NavMenu } from "./NavbarElements";
import {  useLocation } from "react-router-dom";
import { useNavigate, Outlet, NavLink } from "react-router-dom";

const Navbar = () => {
  let location = useLocation(); 
    return (
        <>
        <div className="nav-item">
        <NavLink  className={`nav-link ${location.pathname === "/" ? "active" : ""}`}  to='/'> Home</NavLink>
        </div>
        <div className="nav-item">
        <NavLink  className={`nav-link ${location.pathname === "/" ? "active" : ""}`}  to='/about'> About Us</NavLink>
        </div>
<div className="nav-item dropdown">

<NavLink  className={`nav-link ${location.pathname === "/services" ? "active" : ""} ${location.pathname === "/infrastructure" ? "active" : ""} ${location.pathname === "/business" ? "active" : ""} ${location.pathname === "/erp" ? "active" : ""} ${location.pathname === "/cyber" ? "active" : ""} ${location.pathname === "/micro" ? "active" : ""} ${location.pathname === "/it" ? "active" : ""}`}  to='/services'>Services</NavLink>
 <NavLink id="dropdownMenuButton1"
    data-bs-toggle="dropdown"
    aria-expanded="false" className={`nav-link dropdown-toggle`}  to='/services'></NavLink>
  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li>
    <NavLink className={`dropdown-item ${location.pathname === "/infrastructure" ? "active" : ""}`}  to='/infrastructure'>Infrastructure Solutions</NavLink>
                        </li> 
                        <li>
                      <NavLink className={`dropdown-item ${location.pathname === "/business" ? "active" : ""}`}  to='/business'>Business Analytics</NavLink>
                        </li>               
                      {/* <a href="/infrastructure" className="dropdown-item">
                        Infrastructure Solutions
                      </a> */}
                        <li>
                      <NavLink className={`dropdown-item ${location.pathname === "/erp" ? "active" : ""}`}  to='/erp'>ERP Solutions</NavLink>
                        </li> 
                        <li>
                      <NavLink className={`dropdown-item ${location.pathname === "/cyber" ? "active" : ""}`}  to='/cyber'>Cyber Security</NavLink>
                        </li> 
                        <li>
                      <NavLink className={`dropdown-item ${location.pathname === "/micro" ? "active" : ""}`}  to='/micro'>Micro Services</NavLink>
                        </li> 
                        <li>
                      <NavLink className={`dropdown-item ${location.pathname === "/it" ? "active" : ""}`}  to='/it'>IT Staffing</NavLink>
                        </li> 
                   
  </ul>
</div>

           {/* <li className="nav-item">
  <NavLink className={`nav-link ${location.pathname === "/" ? "active" : ""}`}  to='/'>Home</NavLink>
     </li>
     <li className="nav-item">
  <NavLink className={`nav-link ${location.pathname === "/contact" ? "active" : ""}`}  to='/contact'>contact</NavLink>
     </li>  */}

          
                    {/* <a
                      href="/"
                      className="nav-link dropdown-toggle"
                      data-bs-toggle="dropdown"
                    >
                      About Us
                    </a> */}
              <div className="nav-item dropdown">
              <NavLink className={`nav-link ${location.pathname === "/solutions" ? "active" : ""} ${location.pathname === "/case-studies" ? "active" : ""} ${location.pathname === "/managed-services" ? "active" : ""}`}  to='/solutions'> Solutions</NavLink>
                    <NavLink id="dropdownMenuButton2"
    data-bs-toggle="dropdown"
    aria-expanded="false" className={`nav-link dropdown-toggle`}> </NavLink>
                    
 <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton2">
 <li>
                      <NavLink className={`dropdown-item ${location.pathname === "/managed-services" ? "active" : ""}`}  to='/managed-services'> Managed Services</NavLink>
                      </li>
                      <li>
                      <NavLink className={`dropdown-item ${location.pathname === "/case-studies" ? "active" : ""}`}  to='/case-studies'> Case  Studies</NavLink>
                      </li>

                  
 </ul>
</div>   
                 
                   <div className="nav-item dropdown">
                    {/* <a
                      href="/"
                      className="nav-link dropdown-toggle"
                      data-bs-toggle="dropdown"
                    >
                      About Us
                    </a> */}

                  </div>                      
                    <div className="nav-item">
                    <NavLink className={`nav-link ${location.pathname === "/careers" ? "active" : ""}`}  to='/careers'> Careers</NavLink>
                    </div>
                    <div className="nav-item">
                    <NavLink className={`nav-link ${location.pathname === "/contact" ? "active" : ""}`}  to='/contact'> Contact</NavLink>
                    </div>
                    
        </>
    );
};

export default Navbar;