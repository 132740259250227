function Solutions() {
    return (
      <>

{/* Header Start */}
<div className="container-fluid bg-breadcrumb">
<div className="bg-breadcrumb-single" />
<div className="container text-center py-5" style={{ maxWidth: 900 }}>
  <h4
    className="text-white display-4 mb-4 wow fadeInDown"
    data-wow-delay="0.1s"
  >
    Solutions
  </h4>
 
</div>
</div>
      <div className="container-fluid project">
        <div className="container-fluid">
          <div
            className="text-center mx-auto pb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: 800, marginTop : 40 }}
          >
            <h1 className="dispay-4">Explore Our Solutions</h1>
          </div>
          <div
              className="project-carousel owl-arousel wow fadeInUp"
              data-wow-delay="0.1s"
            >
<div className="col-md-4 project-item h-100 wow fadeInUp" data-wow-delay="0.1s">
                <div className="project-img">
                  <img
                    src="assets/img/data-flow.jpg"
                    className="img-flid w-100 rouned"
                    alt="Image"
                  />
                </div>
                <div className="project-content bg-light rounded p-4">
                  <div className="project-content-inner">
                    <div className="project-icon mb-3">
                      <i className="fas fa-chart-line fa-4x text-primary" />
                    </div>
                    <p className="text-drk fs-5 b-3">Data flow management</p>
                    <a href="/" className="h4">
                      Optimize resource utilization by moving data between data
                      centers or between on-premises infrastructure and cloud...
                    </a>
                    <div className="pt-4">
                      <a className="btn btn-light rounded-pill py-3 px-5" href="/">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 project-item h-100 wow fadeInUp" data-wow-delay="0.1s">
                <div className="project-img">
                  <img
                    src="assets/img/cloud-migration.jpg"
                    className="img-fluid w-100 rounded"
                    alt="Image"
                  />
                </div>
                <div className="project-content bg-light rounded p-4">
                  <div className="project-content-inner">
                    <div className="project-icon mb-3">
                      <i className="fas fa-chart-line fa-4x text-primary" />
                    </div>
                    <p className="text-dark fs-5 mb-3">Cloud Migration</p>
                    <a href="/" className="h4">
                    Because the continuous replication occurs in the background, with no performance disruption, the actual...
                    </a>
                    <div className="pt-4">
                      <a className="btn btn-light rounded-pill py-3 px-5" href="/">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 project-item h-100 wow fadeInUp" data-wow-delay="0.1s">
                <div className="project-img">
                  <img
                    src="assets/img/projects-1.jpg"
                    className="img-fluid w-100 rounded"
                    alt="Image"
                  />
                </div>
                <div className="project-content bg-light rounded p-4">
                  <div className="project-content-inner">
                    <div className="project-icon mb-3">
                      <i className="fas fa-chart-line fa-4x text-primary" />
                    </div>
                    <p className="text-dark fs-5 mb-3">SAP</p>
                    <a href="/" className="h4">
                    The Six Pillars of Modern Dynamic APM. APM in containerized World. Service Quality Management. Instana Success Story.
                    </a>
                    <div className="pt-4">
                      <a className="btn btn-light rounded-pill py-3 px-5" href="/">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>

 
        </div>
        </div>
        
        </div>   
  {/*- END CONTAINER-FLUID */}

</>
    );
};

export default Solutions