function Services() {
    return (
      <>
  
{/* Header Start */}
<div className="container-fluid bg-breadcrumb">
<div className="bg-breadcrumb-single" />
<div className="container text-center py-5" style={{ maxWidth: 900 }}>
  <h4
    className="text-white display-4 mb-4 wow fadeInDown"
    data-wow-delay="0.1s"
  >
    Services
  </h4>
 
</div>
</div>
        <section className="feature section-padding" id="service">
        <div className="container">
          <div className="row text-center">
            <div
              className="section-title wow zoomIn"
              style={{ visibility: "visible", animationName: "zoomIn" }}
            >

              <p></p>
            </div>
            <div style={{ marginBottom: 20 }} className="col-md-12 col-sm-4 col-xs-12">
              <div className="col-md-4 col-sm-4 col-xs-12">
                <div
                  className="about_single wow fadeInLeft"
                  data-wow-delay="0.1s"
                  data-wow-duration="1s"
                  data-wow-offset={0}
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.1s",
                    animationName: "fadeInLeft"
                  }}
                >
                  <h4>Infrastructure Solutions</h4>
                  <p>
                    Empower your business with robust, scalable, and secure
                    infrastructure solutions designed to support...
                  </p>
                  <a href="/infrastructure">Read More</a>
                </div>
              </div>
              {/*- END COL */}
              <div className="col-md-4 col-sm-4 col-xs-12">
                <div
                  className="about_single wow fadeInDown"
                  data-wow-delay="0.1s"
                  data-wow-duration="1s"
                  data-wow-offset={0}
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.1s",
                    animationName: "fadeInDown"
                  }}
                >
                  <h4>Business Analytics</h4>
                  <p>
                    Unlock powerful insights and drive smarter decision-making with
                    our Business Analytics solutions...
                  </p>
                  <a href="/business">Read More</a>
                </div>
              </div>
              {/*- END COL */}
              <div className="col-md-4 col-sm-4 col-xs-12">
                <div
                  className="about_single wow fadeInRight"
                  data-wow-delay="0.1s"
                  data-wow-duration="1s"
                  data-wow-offset={0}
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.1s",
                    animationName: "fadeInRight"
                  }}
                >
                  <h4>ERP Solutions</h4>
                  <p>
                  Streamline, innovate, and scale your business operations with our expert SAP services...
                  </p>
                  <a href="erp">Read More</a>
                </div>
              </div>
            </div>
            <div style={{ marginBottom: 20 }} className="col-md-12 col-sm-4 col-xs-12">
            <div className="col-md-4 col-sm-4 col-xs-12">
                <div
                  className="about_single wow fadeInRight"
                  data-wow-delay="0.1s"
                  data-wow-duration="1s"
                  data-wow-offset={0}
                  style={{
                    visibility: "hidden",
                    animationDuration: "1s",
                    animationDelay: "0.1s",
                    animationName: "none"
                  }}
                >
                  <h4>Cyber Security</h4>
                  <p>
                    In today's digital landscape, robust cybersecurity is essential
                    for safeguarding sensitive data...
                  </p>
                  <a href="cyber">Read More</a>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-xs-12">
                <div
                  className="about_single wow fadeInRight"
                  data-wow-delay="0.1s"
                  data-wow-duration="1s"
                  data-wow-offset={0}
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.1s",
                    animationName: "fadeInRight"
                  }}
                >
                  <h4>Micro Services</h4>
                  <p>
                    OIn today's digital landscape, seamless communication between
                    applications is essential...
                  </p>
                  <a href="micro">Read More</a>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-xs-12">
                <div
                  className="about_single wow fadeInRight"
                  data-wow-delay="0.1s"
                  data-wow-duration="1s"
                  data-wow-offset={0}
                  style={{
                    visibility: "hidden",
                    animationDuration: "1s",
                    animationDelay: "0.1s",
                    animationName: "none"
                  }}
                >
                  <h4>IT Staffing</h4>
                  <p>
                    Finding the right talent to drive your IT projects forward is
                    essential. Our specialized technology...
                  </p>
                  <a href="it">Read More</a>
                </div>
              </div>
              {/*- END COL */}
              {/*- END COL */}
            </div>
            </div>
            {/*- END COL */}
            
          {/*- END CONTAINER */}
        </div>
      </section>
      
  
      </>
    );
};

export default Services