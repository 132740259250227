function Careers() {
    return (
      <>
  
{/* Header Start */}
<div className="container-fluid bg-breadcrumb">
<div className="bg-breadcrumb-single" />
<div className="container text-center py-5" style={{ maxWidth: 900 }}>
  <h4
    className="text-white display-4 mb-4 wow fadeInDown"
    data-wow-delay="0.1s"
  >
    Careers
  </h4>
 
</div>
</div>
<div className="container">
<section className="portfolio section-padding" id="portfolio">
  <div className="row">
    <div className="section-title  wow zoomIn">
      <div className="indented">
      <iframe
          src="https://celersoft.oorwin.com/careers/index.html"
          width="100%"
          height={600}
          iframeborder={0}
          style={{ border: "none", margin: 0, marginTop: 50 }}
        />
      </div>
    </div>
  </div>
</section>
</div>

</>
);
};

export default Careers